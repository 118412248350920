.search-bar {
  display: flex;
  align-items: center;
  @apply justify-center w-[498px] xl:justify-end 2xl:justify-center;
}

@media (max-width: 1023px) {
  .search-bar {
    @apply w-[100vw];
  }
}

.logo img {
  width: 100px;
}

.search-area {
  flex: 1;
  display: flex;
  align-items: center;
}

.product-label {
  font-size: 16px;
  margin-right: 8px;
  line-height: 1.25em;
  min-width: 40px;
}

.search-input-wrapper {
  flex: 1;
  display: flex;
}

.search-area .ant-input-affix-wrapper {
  flex: 1;
  height: 60px !important;
  border-radius: 0;
}
.search-area .ant-input-outlined {
  border: 0px;
  border-bottom: 2px solid #757c85;
}

.search-area .ant-input-affix-wrapper input {
  height: 100%;
}

.search-area .ant-input-search-button {
  height: 100%;
}

.search-area .ant-btn-icon-only {
  display: flex;
  align-items: center;
}

.max-width {
  max-width: 580px;
}
