.search-history-tag {
  border: 0;
  padding: 12px;
  background: transparent;
  font-weight: 400;
  font-size: 15px;
  height: 44px;
}
.search-history-tag:hover {
  background: #f6f6f6;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 1;
}
.search-history-tag .anticon {
  margin-left: 12px;
}
.search-history-tag svg {
  width: 16px;
  height: 16px;
}

.search-title {
  font-weight: 700;
  font-size: 15px;
  height: 44px;
  display: flex;
  align-items: center;
}
