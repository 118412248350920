.line-height-small {
}

.line-height-small > * {
  line-height: 1.5em;
}

.line-height-small a:hover {
  @apply text-primary;
}

.sticky-element {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background: #f0f0f0;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
}

.hidden {
  transform: translate3d(0, -100%, 0); /* 使用translate3d提高性能 */
}

.cart-icon {
  display: inline-block;
  position: relative;
}

/* 定义动画 */
@keyframes cart-bounce {
  50% {
    @apply text-primary;
  }
}

.cart-icon {
  animation: cart-bounce 0.5s ease-in-out; /* 动画时间0.5秒，向上弹跳 */
  animation-iteration-count: 3; /* 动画重复3次 */
}

/* 添加动画结束后的效果 */
.cart-icon.active {
  animation: cart-bounce 0.5s ease-in-out 3;
}

/*   */
.category-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px; /* 图标的宽度 */
  height: 20px; /* 图标的高度 */
  cursor: pointer;
}

.category-icon span {
  display: block;
  width: 100%;
  height: 1px; /* 每条线的高度 */
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 1px;
  transition: all 0.3s ease-in-out; /* 定义动画过渡效果 */
}

.line-1 {
  transform: translateY(6.25px) scale(0.6) rotate(45deg); /* 第1条线旋转 */
}

.line-2 {
  opacity: 0; /* 中间的线隐藏 */
}

.line-3 {
  transform: translateY(-6.25px) scale(0.6) rotate(-45deg); /* 第3条线旋转 */
}

.category-list {
}

.category-list .column-list-1,
.category-list .column-list-2 {
  /* @apply w-[0px]; */
  display: flex;
  flex-direction: column;
}

.category-list:hover .column-list-1,
.category-list:hover .column-list-2 {
  @apply zr:w-[100vw] md:w-[384px] lg:w-[384px] xl:w-[404px] 2xl:w-[440px];
}

.category-list:active .image-label,
.category-list:active > div:nth-child(2),
.category-list:hover .image-label,
.category-list:hover > div:nth-child(2) {
  display: flex;
}
.category-image {
  /* margin: 12px 20px 12px 40px; */
  @apply zr:mt-[3px] zr:mr-[8px] zr:mb-[3px] zr:ml-[16px];
  @apply md:mt-[5px] lg:mr-[20px] lg:mb-[5px] lg:ml-[28px];
  @apply xl:mt-[12px] xl:mr-[20px] xl:mb-[12px] xl:ml-[28px];
  @apply 2xl:mt-[12px] 2xl:mr-[20px] 2xl:mb-[12px] 2xl:ml-[40px];
  width: 50px;
  height: 50px;
  @apply zr:p-[5px];
  @apply md:p-[5px];
  @apply lg:p-[5px];
  @apply xl:p-[3px];
  @apply 2xl:p-[0px];
}

.image-label {
  display: none;
  font-weight: 400;
  font-size: 18px;
  align-self: stretch;
}
.child-col-padding {
  padding-left: 40px;
  padding-right: 4px;
}

.image-label > span {
  /* @apply zr:leading-[56px] zr:h-[56px];
  @apply md:leading-[60px] md:h-[60px];*/
  @apply xl:leading-[68px] md:h-[68px];
  @apply 2xl:leading-[74px] 2xl:h-[74px];
}

.image-label:hover .arrow {
  visibility: visible;
}
.arrow {
  /* visibility: hidden; */
  font-size: 12px;
  float: right;
  padding-left: 16px;
  padding-right: 16px;
}

.shadow {
  box-shadow: 0px 4px 20px 0px #0000000d;
}

.category-item-hover-bg:hover {
  background-color: #f6f6f6;
}

.active-category-item {
  background-color: #f6f6f6;
}
active-category-item .arrow {
  visibility: visible;
}

.backdrop-bg {
  background-color: var(--backdrop-color);
}

.rotate180 {
  transform: rotateX("180");
}
